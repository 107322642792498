/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'AirLiquide',
        clientId: 'vi-air-liquide-staging',
        buttonText: 'Identification Air Liquide',
        autoLogin: true,
      },
    ],
  ],
}
